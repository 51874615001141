<template>
	<div class="commonHead">
		<div class="head">
			<div class="left" v-if="isReturn" @click="onClickLeft">
				<div class="icon"><i class="icon_arrL"></i></div>
			</div>
			<div class="title">
				<span v-if="isReturn">{{ title }}</span>
				<strong v-else>{{ title }}</strong>
			</div>
			<div class="right" @click="onclickRight">
				<slot name="text"></slot>
				<slot name="right"></slot>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'commonHead',
	    props: {
	        title: {
	            type: String,
	            default: ''
	        },
	        url: {
	            type: String,
	            default: '/'
	        },
	        isReturn: {
	            type: Boolean,
	            default: true
	        },
            query: {
                type: Object,
                default: () => {}
            }
	    },
	    methods: {
	        onClickLeft() {
	            if (this.url && this.url !== '/') {
	                this.$router.push({
	                    path: this.url,
	                    query: this.query
	                });
	            } else {
	                this.$router.back(-1);
	            }
	        },
			onclickRight() {
				this.$emit('onclickRight')
			},
	    }
	}
</script>

<style lang="less">
	.commonHead {
		padding: 0 .3rem;
		background-image: url(../assets/imgs/common_bg.png);
		background-repeat: no-repeat;
		background-position: center top;
		background-size: 100% 3.5rem;
		.head {
			position: relative;
			height: .88rem;
			line-height: .88rem;
		}
		.left {
			position: absolute;
			left: 0;
			top: 0;
			.icon {
				width: .6rem;
				height: .88rem;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					width: .4rem;
					height: .4rem;
				}
			}
		}
		.title {
			color: #ffffff;
			font-size: .32rem;
			// font-weight: bold;
			text-align: center;
			text-transform: capitalize;
		}
		.right {
			position: absolute;
			right: 0;
			top: 0;
			height: .88rem;
			display: flex;
			align-items: center;
			.text {
				color: #FFFFFF;
				font-size: .24rem;
			}
			img {
				width: .4rem;
				height: .4rem;
			}
		}
	}
</style>
